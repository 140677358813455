import React, { useEffect, useState } from 'react'
import './HoliAnimation.scss'
import WorksItem from '../WorksItem'
// import HoliAnimation from './HoliAnimation'

export default function Holi() {

    let [shouldAnimate, setShouldAnimate] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShouldAnimate(!shouldAnimate);
        }, 3000)
        return () => {
            clearInterval(interval)
        };
    });

    const desc = (<>
        <p>The logo incorporates the first letters of the brand name while also striving to bring to mind the company's core values, which are harmony and a holistic approach to human body and mind.</p>
        <p>The animation is done using only CSS animations.</p>
    </>)

    return (
        <WorksItem
            title="Logo and Animation for a medical center"
            description={desc}
            used={['ai', 'css']}
        >
            <div className='holi-content'>
                <img className='holi-logo' src="/assets/holi-logo.svg" alt="HoliClinic" />
                <div className='holi-animation'>
                    <svg viewBox="0 0 79.57 80.55" className={shouldAnimate ? 'animate' : ''}>
                        <g className="logo">
                            <g className="flower">
                                <path className="petal" opacity="0.6" fill="#B2768D"
                                    d="M39.783,1c-16.02,16.021-16.02,41.998,0,58.018 C55.806,42.996,55.806,17.021,39.783,1z" />
                                <path className="petal" opacity="0.6" fill="#B2768D"
                                    d="M3.275,22.507L3.275,22.507 c0,20.165,16.346,36.51,36.508,36.51C39.783,38.853,23.437,22.507,3.275,22.507z" />
                                <path className="petal" opacity="0.6" fill="#B2768D"
                                    d="M39.783,59.017h0.002c20.162,0,36.508-16.346,36.508-36.51l0,0 C56.129,22.507,39.783,38.853,39.783,59.017z" />
                            </g>
                            <g className="letters">
                                <polyline
                                    points="3.275,77.271 39.784,77.271 76.293,77.271 39.784,77.271 39.784,59.018 3.273,59.016 76.293,59.018 		" />
                                <path d="M76.296,22.507c0,20.165-16.347,36.511-36.511,36.511 c-20.165,0-36.511-16.347-36.511-36.511" />
                            </g>
                        </g>
                        {/* <g className="title">
                        <polygon points="141.436,223.613 141.436,205.848 119.031,205.848 119.031,223.613 114.981,223.613 114.981,185.463     119.031,185.463 119.031,202.348 141.436,202.348 141.436,185.463 145.486,185.463 145.486,223.613   " />
                        <path d="M167.396,223.85c-2.25,0-4.298-0.457-6.143-1.372c-1.845-0.915-3.428-2.13-4.748-3.645c-1.32-1.514-2.34-3.247-3.06-5.198    c-0.719-1.95-1.08-3.945-1.08-5.985c0-2.13,0.383-4.17,1.147-6.12c0.765-1.95,1.823-3.674,3.173-5.175    c1.35-1.499,2.947-2.692,4.792-3.577c1.845-0.884,3.847-1.327,6.007-1.327c2.25,0,4.29,0.472,6.12,1.417    c1.83,0.945,3.404,2.182,4.725,3.712c1.319,1.53,2.34,3.262,3.06,5.197c0.72,1.935,1.08,3.908,1.08,5.917    c0,2.131-0.382,4.17-1.147,6.12c-0.765,1.951-1.815,3.667-3.15,5.152c-1.335,1.485-2.925,2.671-4.77,3.555    C171.558,223.408,169.556,223.85,167.396,223.85z M156.461,207.65c0,1.62,0.262,3.188,0.788,4.702    c0.524,1.515,1.267,2.858,2.228,4.028c0.959,1.17,2.115,2.1,3.465,2.79c1.35,0.69,2.85,1.035,4.5,1.035    c1.71,0,3.24-0.367,4.59-1.103c1.35-0.735,2.498-1.694,3.443-2.88c0.945-1.185,1.665-2.527,2.16-4.028    c0.495-1.5,0.743-3.014,0.743-4.545c0-1.62-0.263-3.187-0.788-4.703c-0.525-1.514-1.275-2.85-2.25-4.005    c-0.975-1.154-2.13-2.077-3.465-2.768c-1.335-0.689-2.813-1.035-4.433-1.035c-1.71,0-3.24,0.36-4.59,1.08    c-1.35,0.72-2.498,1.665-3.443,2.835c-0.945,1.17-1.672,2.504-2.182,4.004C156.716,204.561,156.461,206.091,156.461,207.65z" />
                        <path d="M188.095,223.625v-31.95h4.05v28.35h17.82v3.6H188.095z" />
                        <path d="M214.376,223.625v-31.95h4.05v31.95H214.376z" />
                        <path d="M242.71,223.943c-2.593,0-5.002-0.575-7.161-1.708c-2.177-1.142-4.085-2.652-5.671-4.488    c-1.59-1.839-2.839-3.958-3.714-6.299c-0.875-2.338-1.319-4.755-1.319-7.184c0-2.283,0.408-4.593,1.211-6.863    c0.803-2.264,1.996-4.326,3.547-6.126c1.548-1.797,3.471-3.268,5.715-4.372c2.238-1.101,4.817-1.659,7.666-1.659    c3.379,0,6.314,0.772,8.725,2.296c2.292,1.45,4.044,3.339,5.214,5.62l-3.144,2.037c-0.561-1.089-1.224-2.028-1.976-2.797    c-0.853-0.872-1.797-1.584-2.806-2.118c-1.006-0.531-2.053-0.915-3.114-1.142c-1.052-0.226-2.102-0.34-3.119-0.34    c-2.229,0-4.235,0.471-5.961,1.401c-1.716,0.925-3.179,2.149-4.348,3.638c-1.166,1.486-2.052,3.18-2.633,5.038    c-0.579,1.848-0.873,3.735-0.873,5.607c0,2.098,0.352,4.121,1.045,6.012c0.693,1.892,1.673,3.594,2.912,5.059    c1.244,1.47,2.734,2.654,4.429,3.52c1.704,0.872,3.587,1.314,5.595,1.314c1.058,0,2.157-0.133,3.266-0.397    c1.119-0.265,2.205-0.698,3.228-1.285c1.019-0.585,1.985-1.33,2.871-2.218c0.79-0.79,1.462-1.731,2-2.803l3.344,1.828    c-0.585,1.258-1.375,2.388-2.351,3.363c-1.081,1.082-2.323,2.009-3.692,2.756c-1.373,0.749-2.846,1.327-4.378,1.719    C245.684,223.744,244.167,223.943,242.71,223.943z" />
                        <path d="M263.585,223.625v-31.95h4.05v28.35h17.82v3.6H263.585z" />
                        <path d="M289.865,223.625v-31.95h4.05v31.95H289.865z" />
                        <path d="M305.615,199.235v24.39h-4.05v-31.95h3.15l19.8,24.885v-24.84h4.05v31.905h-3.42L305.615,199.235z" />
                        <path d="M336.214,223.625v-31.95h4.05v31.95H336.214z" />
                        <path d="M345.844,207.425c0-1.919,0.338-3.832,1.013-5.737c0.675-1.905,1.665-3.615,2.97-5.13    c1.306-1.515,2.902-2.737,4.793-3.667c1.889-0.93,4.034-1.395,6.435-1.395c2.85,0,5.295,0.645,7.335,1.935s3.556,2.97,4.545,5.04    l-3.194,2.07c-0.481-1.019-1.058-1.874-1.732-2.564c-0.676-0.69-1.411-1.245-2.205-1.666c-0.796-0.42-1.613-0.72-2.453-0.9    c-0.84-0.18-1.665-0.27-2.475-0.27c-1.771,0-3.338,0.368-4.703,1.103c-1.365,0.735-2.513,1.695-3.442,2.88    c-0.93,1.186-1.627,2.52-2.092,4.005c-0.466,1.485-0.698,2.978-0.698,4.477c0,1.681,0.277,3.278,0.833,4.793    c0.555,1.515,1.327,2.857,2.317,4.027c0.99,1.17,2.16,2.101,3.51,2.79c1.351,0.69,2.819,1.035,4.41,1.035    c0.84,0,1.702-0.105,2.588-0.315c0.884-0.209,1.732-0.547,2.542-1.012c0.811-0.465,1.567-1.05,2.272-1.755    s1.283-1.552,1.732-2.543l3.375,1.845c-0.51,1.2-1.223,2.258-2.137,3.172c-0.916,0.916-1.951,1.688-3.105,2.318    c-1.155,0.63-2.378,1.11-3.668,1.44c-1.29,0.33-2.55,0.495-3.779,0.495c-2.19,0-4.201-0.479-6.03-1.439    c-1.83-0.96-3.413-2.212-4.747-3.758c-1.336-1.544-2.371-3.299-3.105-5.265C346.211,211.468,345.844,209.466,345.844,207.425z" />
                    </g>
                    <g className="subtitle">
                        <path d="M114.981,250.792v-9.024h5.872v0.584h-5.224v3.584h4.436v0.559h-4.436v4.296H114.981z" />
                        <path d="M122.531,250.792v-9.024h0.648v9.024H122.531z" />
                        <path d="M124.742,250.259l6.419-7.906h-6.33v-0.584h7.093v0.533l-6.368,7.906h6.343v0.584h-7.156V250.259z" />
                        <path d="M132.737,249.852c0.212,0.144,0.466,0.26,0.763,0.349c0.297,0.089,0.627,0.134,0.991,0.134    c0.491,0,0.881-0.089,1.169-0.267c0.288-0.178,0.508-0.437,0.661-0.776c0.152-0.338,0.252-0.755,0.299-1.252    c0.047-0.495,0.07-1.056,0.07-1.684v-4.588h0.648v4.588c0,0.687-0.03,1.312-0.089,1.875c-0.06,0.563-0.189,1.044-0.388,1.443    c-0.199,0.397-0.485,0.707-0.858,0.927s-0.877,0.33-1.512,0.33c-0.763,0-1.415-0.187-1.958-0.559L132.737,249.852z" />
                        <path d="M143.185,250.856c-0.627,0-1.199-0.131-1.716-0.394c-0.517-0.263-0.962-0.606-1.334-1.03    c-0.373-0.424-0.661-0.91-0.864-1.461c-0.204-0.552-0.305-1.115-0.305-1.691c0-0.601,0.109-1.178,0.324-1.728    c0.216-0.551,0.512-1.038,0.889-1.462c0.377-0.423,0.824-0.76,1.341-1.01c0.517-0.25,1.076-0.375,1.678-0.375    c0.627,0,1.201,0.133,1.722,0.4c0.521,0.267,0.966,0.617,1.335,1.049c0.368,0.432,0.654,0.922,0.858,1.468    c0.203,0.546,0.305,1.1,0.305,1.658c0,0.611-0.108,1.19-0.324,1.742c-0.216,0.551-0.513,1.036-0.89,1.455    c-0.377,0.419-0.824,0.754-1.341,1.004C144.346,250.731,143.787,250.856,143.185,250.856z M139.613,246.28    c0,0.517,0.087,1.016,0.26,1.493c0.173,0.479,0.419,0.903,0.737,1.272s0.697,0.663,1.138,0.883c0.44,0.22,0.923,0.331,1.449,0.331    c0.542,0,1.031-0.114,1.468-0.343c0.437-0.229,0.809-0.532,1.119-0.909c0.309-0.377,0.548-0.803,0.718-1.277    c0.169-0.475,0.254-0.958,0.254-1.449c0-0.516-0.089-1.014-0.267-1.493c-0.178-0.478-0.426-0.902-0.743-1.27    c-0.319-0.369-0.693-0.664-1.125-0.884c-0.433-0.22-0.907-0.331-1.424-0.331c-0.542,0-1.034,0.115-1.474,0.343    c-0.441,0.229-0.818,0.532-1.132,0.909c-0.313,0.377-0.555,0.802-0.724,1.277C139.698,245.306,139.613,245.79,139.613,246.28z" />
                        <path d="M155.044,242.353h-3.305v8.439h-0.648v-8.439h-3.305v-0.584h7.258V242.353z" />
                        <path d="M162.454,250.208v0.584h-6.012v-9.024h5.897v0.584h-5.249v3.546h4.588v0.559h-4.588v3.75H162.454z" />
                        <path d="M164.22,250.792v-9.024h3.75c0.381,0,0.732,0.08,1.055,0.241c0.322,0.161,0.599,0.374,0.832,0.641    c0.233,0.268,0.416,0.568,0.547,0.903c0.131,0.334,0.197,0.671,0.197,1.011c0,0.33-0.051,0.65-0.152,0.959    c-0.102,0.309-0.246,0.586-0.433,0.832c-0.186,0.246-0.411,0.449-0.673,0.611c-0.263,0.16-0.551,0.267-0.864,0.318l2.25,3.507    h-0.75l-2.186-3.406h-2.924v3.406H164.22z M164.868,246.788h3.165c0.288,0,0.551-0.063,0.787-0.191    c0.238-0.127,0.441-0.294,0.611-0.501c0.169-0.208,0.299-0.445,0.388-0.712c0.089-0.267,0.133-0.54,0.133-0.819    c0-0.288-0.053-0.566-0.159-0.833c-0.106-0.267-0.25-0.502-0.432-0.706c-0.182-0.203-0.394-0.366-0.636-0.49    c-0.241-0.122-0.502-0.184-0.781-0.184h-3.076V246.788z" />
                        <path d="M175.164,241.769h0.559l3.813,9.024h-0.686l-1.259-2.974h-4.308l-1.259,2.974h-0.699L175.164,241.769z M177.414,247.285    l-1.97-4.729l-2.008,4.729H177.414z" />
                        <path d="M180.909,250.792v-9.024h3.712c0.381,0,0.733,0.08,1.054,0.241c0.323,0.161,0.6,0.374,0.833,0.641    c0.233,0.268,0.415,0.568,0.547,0.903c0.131,0.334,0.197,0.671,0.197,1.011c0,0.364-0.062,0.715-0.184,1.054    c-0.123,0.339-0.297,0.64-0.521,0.902c-0.225,0.263-0.494,0.473-0.807,0.629c-0.314,0.157-0.661,0.236-1.042,0.236h-3.14v3.406    H180.909z M181.557,246.788h3.114c0.296,0,0.563-0.06,0.801-0.184c0.237-0.123,0.439-0.288,0.604-0.495    c0.165-0.208,0.292-0.445,0.381-0.713c0.089-0.267,0.134-0.544,0.134-0.831c0-0.297-0.053-0.579-0.159-0.846    s-0.25-0.502-0.432-0.706c-0.182-0.203-0.395-0.365-0.636-0.482c-0.241-0.118-0.498-0.178-0.769-0.178h-3.038V246.788z" />
                        <path d="M188.802,250.792v-9.024h0.648v9.024H188.802z" />
                        <path d="M194.649,241.769h0.559l3.813,9.024h-0.686l-1.258-2.974h-4.309l-1.258,2.974h-0.699L194.649,241.769z M196.899,247.285    l-1.97-4.729l-2.008,4.729H196.899z" />
                        <path d="M209.799,250.792v-9.024h3c0.712,0,1.336,0.118,1.875,0.355c0.538,0.238,0.985,0.56,1.341,0.966    c0.356,0.407,0.623,0.884,0.8,1.43c0.178,0.546,0.267,1.13,0.267,1.748c0,0.687-0.1,1.308-0.299,1.862    c-0.199,0.555-0.485,1.03-0.857,1.424c-0.374,0.394-0.825,0.699-1.354,0.915c-0.529,0.216-1.121,0.324-1.773,0.324H209.799z     M216.434,246.268c0-0.567-0.081-1.091-0.241-1.57c-0.161-0.479-0.396-0.892-0.706-1.24c-0.309-0.347-0.688-0.619-1.138-0.813    c-0.449-0.194-0.966-0.292-1.551-0.292h-2.352v7.855h2.352c0.593,0,1.116-0.1,1.57-0.299c0.454-0.198,0.833-0.477,1.138-0.833    c0.305-0.356,0.536-0.773,0.693-1.252C216.355,247.346,216.434,246.828,216.434,246.268z" />
                        <path d="M218.861,250.792v-9.024h0.648v9.024H218.861z" />
                        <path d="M227.885,250.208v0.584h-6.012v-9.024h5.897v0.584h-5.249v3.546h4.588v0.559h-4.588v3.75H227.885z" />
                        <path d="M235.957,242.353h-3.305v8.439h-0.648v-8.439h-3.305v-0.584h7.258V242.353z" />
                        <path d="M243.366,250.208v0.584h-6.012v-9.024h5.897v0.584h-5.249v3.546h4.589v0.559h-4.589v3.75H243.366z" />
                        <path d="M251.438,242.353h-3.305v8.439h-0.648v-8.439h-3.305v-0.584h7.258V242.353z" />
                        <path d="M252.607,241.769l3.152,4.994l3.165-4.994h0.725l-3.559,5.618v3.406h-0.648v-3.431l-3.546-5.592H252.607z" />
                        <path d="M261.059,250.792v-9.024h0.648v5.478l5.262-5.478h0.75l-3.749,3.952l3.94,5.071h-0.737l-3.623-4.677l-1.843,1.894v2.783    H261.059z" />
                        <path d="M271.965,241.769h0.559l3.813,9.024h-0.686l-1.258-2.974h-4.309l-1.258,2.974h-0.699L271.965,241.769z M274.215,247.285    l-1.97-4.729l-2.008,4.729H274.215z" />
                        <path d="M287.115,250.792v-9.024h3.712c0.381,0,0.732,0.08,1.054,0.241c0.322,0.161,0.6,0.374,0.833,0.641    c0.233,0.268,0.415,0.568,0.547,0.903c0.131,0.334,0.197,0.671,0.197,1.011c0,0.364-0.062,0.715-0.184,1.054    c-0.123,0.339-0.297,0.64-0.521,0.902c-0.224,0.263-0.493,0.473-0.807,0.629c-0.313,0.157-0.661,0.236-1.042,0.236h-3.14v3.406    H287.115z M287.762,246.788h3.115c0.296,0,0.563-0.06,0.801-0.184c0.237-0.123,0.439-0.288,0.604-0.495    c0.165-0.208,0.292-0.445,0.381-0.713c0.089-0.267,0.134-0.544,0.134-0.831c0-0.297-0.053-0.579-0.159-0.846    c-0.106-0.267-0.25-0.502-0.432-0.706c-0.182-0.203-0.395-0.365-0.636-0.482c-0.241-0.118-0.498-0.178-0.769-0.178h-3.038V246.788    z" />
                        <path d="M300.257,243.268c-0.585-0.652-1.411-0.978-2.479-0.978c-0.882,0-1.523,0.167-1.925,0.502    c-0.403,0.335-0.604,0.786-0.604,1.354c0,0.288,0.051,0.528,0.152,0.718c0.102,0.19,0.26,0.354,0.477,0.49    c0.216,0.136,0.489,0.25,0.819,0.343c0.331,0.094,0.729,0.191,1.195,0.292c0.483,0.102,0.922,0.214,1.315,0.337    c0.394,0.123,0.729,0.278,1.004,0.463c0.275,0.187,0.487,0.416,0.635,0.687c0.148,0.271,0.222,0.605,0.222,1.003    c0,0.399-0.078,0.746-0.235,1.043c-0.157,0.296-0.377,0.544-0.661,0.743c-0.284,0.199-0.623,0.35-1.017,0.451    c-0.394,0.102-0.833,0.153-1.316,0.153c-1.406,0-2.597-0.448-3.572-1.347l0.344-0.522c0.381,0.399,0.847,0.715,1.398,0.947    c0.551,0.234,1.169,0.35,1.855,0.35c0.788,0,1.405-0.145,1.849-0.432c0.445-0.289,0.667-0.725,0.667-1.31    c0-0.297-0.059-0.549-0.177-0.757c-0.119-0.207-0.295-0.387-0.528-0.539c-0.233-0.152-0.527-0.284-0.883-0.394    c-0.356-0.11-0.771-0.217-1.246-0.319c-0.483-0.101-0.909-0.209-1.277-0.323c-0.368-0.115-0.679-0.259-0.928-0.432    c-0.25-0.174-0.439-0.385-0.565-0.629c-0.127-0.245-0.19-0.555-0.19-0.928c0-0.407,0.078-0.767,0.235-1.081    c0.156-0.314,0.375-0.578,0.654-0.795c0.28-0.216,0.614-0.379,1.004-0.489c0.389-0.11,0.822-0.165,1.296-0.165    c0.584,0,1.106,0.087,1.563,0.26c0.458,0.174,0.873,0.439,1.246,0.795L300.257,243.268z" />
                        <path d="M302.278,241.769l3.152,4.994l3.165-4.994h0.725l-3.559,5.618v3.406h-0.648v-3.431l-3.546-5.592H302.278z" />
                        <path d="M309.497,246.217c0-0.542,0.095-1.082,0.286-1.62c0.19-0.538,0.468-1.019,0.832-1.443c0.365-0.423,0.811-0.77,1.341-1.036    s1.133-0.399,1.812-0.399c0.805,0,1.491,0.184,2.059,0.552c0.568,0.368,0.992,0.841,1.271,1.417l-0.534,0.305    c-0.161-0.313-0.35-0.576-0.566-0.788c-0.216-0.211-0.449-0.383-0.699-0.515c-0.25-0.131-0.511-0.226-0.782-0.285    c-0.271-0.06-0.538-0.089-0.801-0.089c-0.577,0-1.085,0.116-1.526,0.349c-0.44,0.233-0.813,0.536-1.118,0.909    c-0.305,0.374-0.536,0.795-0.693,1.265s-0.235,0.943-0.235,1.417c0,0.534,0.093,1.043,0.279,1.525    c0.186,0.483,0.441,0.909,0.763,1.278c0.322,0.368,0.703,0.663,1.144,0.883c0.44,0.22,0.915,0.331,1.424,0.331    c0.271,0,0.551-0.035,0.838-0.102c0.289-0.068,0.564-0.174,0.826-0.318s0.508-0.328,0.737-0.553    c0.229-0.225,0.42-0.49,0.572-0.794l0.559,0.267c-0.153,0.339-0.36,0.64-0.623,0.903c-0.263,0.261-0.555,0.482-0.877,0.66    c-0.322,0.178-0.663,0.311-1.023,0.4c-0.36,0.09-0.714,0.134-1.061,0.134c-0.619,0-1.185-0.136-1.697-0.406    c-0.513-0.271-0.956-0.625-1.328-1.062c-0.373-0.436-0.663-0.932-0.87-1.487C309.601,247.359,309.497,246.793,309.497,246.217z" />
                        <path d="M325.881,241.769v9.024h-0.648v-4.322h-5.695v4.322h-0.648v-9.024h0.648v4.117h5.695v-4.117H325.881z" />
                        <path d="M331.83,250.856c-0.628,0-1.199-0.131-1.716-0.394c-0.517-0.263-0.962-0.606-1.334-1.03    c-0.374-0.424-0.662-0.91-0.865-1.461c-0.203-0.552-0.305-1.115-0.305-1.691c0-0.601,0.108-1.178,0.324-1.728    c0.216-0.551,0.512-1.038,0.89-1.462c0.377-0.423,0.824-0.76,1.341-1.01c0.517-0.25,1.076-0.375,1.678-0.375    c0.626,0,1.201,0.133,1.722,0.4c0.521,0.267,0.966,0.617,1.334,1.049c0.369,0.432,0.655,0.922,0.858,1.468    c0.203,0.546,0.305,1.1,0.305,1.658c0,0.611-0.108,1.19-0.324,1.742c-0.216,0.551-0.513,1.036-0.89,1.455    c-0.376,0.419-0.824,0.754-1.341,1.004C332.991,250.731,332.431,250.856,331.83,250.856z M328.258,246.28    c0,0.517,0.087,1.016,0.261,1.493c0.174,0.479,0.419,0.903,0.737,1.272c0.318,0.368,0.697,0.663,1.138,0.883    c0.441,0.22,0.924,0.331,1.45,0.331c0.542,0,1.031-0.114,1.468-0.343c0.436-0.229,0.809-0.532,1.118-0.909    c0.31-0.377,0.549-0.803,0.718-1.277c0.169-0.475,0.254-0.958,0.254-1.449c0-0.516-0.089-1.014-0.267-1.493    c-0.177-0.478-0.426-0.902-0.743-1.27c-0.318-0.369-0.692-0.664-1.125-0.884c-0.432-0.22-0.906-0.331-1.423-0.331    c-0.543,0-1.034,0.115-1.475,0.343c-0.441,0.229-0.818,0.532-1.131,0.909c-0.313,0.377-0.555,0.802-0.724,1.277    C328.343,245.306,328.258,245.79,328.258,246.28z" />
                        <path d="M337.802,250.792v-9.024h0.649v8.439h5.389v0.584H337.802z" />
                        <path d="M348.213,250.856c-0.627,0-1.199-0.131-1.716-0.394c-0.517-0.263-0.962-0.606-1.334-1.03    c-0.374-0.424-0.662-0.91-0.865-1.461c-0.203-0.552-0.305-1.115-0.305-1.691c0-0.601,0.108-1.178,0.324-1.728    c0.216-0.551,0.512-1.038,0.889-1.462c0.377-0.423,0.824-0.76,1.341-1.01c0.517-0.25,1.076-0.375,1.678-0.375    c0.626,0,1.201,0.133,1.722,0.4c0.521,0.267,0.966,0.617,1.334,1.049c0.37,0.432,0.655,0.922,0.858,1.468s0.305,1.1,0.305,1.658    c0,0.611-0.107,1.19-0.323,1.742c-0.216,0.551-0.513,1.036-0.89,1.455c-0.376,0.419-0.824,0.754-1.341,1.004    C349.374,250.731,348.814,250.856,348.213,250.856z M344.641,246.28c0,0.517,0.087,1.016,0.261,1.493    c0.174,0.479,0.419,0.903,0.737,1.272c0.317,0.368,0.697,0.663,1.137,0.883c0.44,0.22,0.924,0.331,1.449,0.331    c0.542,0,1.031-0.114,1.468-0.343c0.436-0.229,0.809-0.532,1.118-0.909c0.31-0.377,0.549-0.803,0.719-1.277    c0.169-0.475,0.254-0.958,0.254-1.449c0-0.516-0.089-1.014-0.267-1.493c-0.178-0.478-0.426-0.902-0.744-1.27    c-0.317-0.369-0.692-0.664-1.125-0.884c-0.432-0.22-0.906-0.331-1.423-0.331c-0.542,0-1.034,0.115-1.475,0.343    c-0.441,0.229-0.818,0.532-1.131,0.909c-0.314,0.377-0.555,0.802-0.724,1.277C344.726,245.306,344.641,245.79,344.641,246.28z" />
                        <path d="M360.834,249.382c-0.873,0.983-1.885,1.475-3.038,1.475c-0.61,0-1.176-0.131-1.697-0.394    c-0.522-0.263-0.969-0.61-1.341-1.043c-0.374-0.432-0.666-0.923-0.878-1.474c-0.211-0.552-0.318-1.11-0.318-1.678    c0-0.593,0.104-1.166,0.312-1.716c0.208-0.551,0.496-1.038,0.864-1.462c0.369-0.423,0.807-0.76,1.316-1.01    c0.508-0.25,1.063-0.375,1.666-0.375c0.448,0,0.853,0.049,1.214,0.146c0.36,0.098,0.682,0.233,0.966,0.407s0.53,0.381,0.737,0.623    c0.208,0.241,0.384,0.506,0.528,0.794l-0.534,0.318c-0.305-0.593-0.709-1.022-1.214-1.291c-0.504-0.267-1.074-0.4-1.709-0.4    c-0.534,0-1.018,0.115-1.45,0.343c-0.432,0.229-0.8,0.53-1.105,0.903c-0.305,0.373-0.539,0.796-0.699,1.271    c-0.161,0.474-0.242,0.962-0.242,1.461c0,0.534,0.094,1.041,0.281,1.519c0.186,0.479,0.442,0.901,0.768,1.265    c0.326,0.364,0.709,0.655,1.15,0.87c0.441,0.216,0.916,0.325,1.424,0.325c0.55,0,1.074-0.127,1.57-0.381    c0.496-0.254,0.972-0.656,1.43-1.208v-1.74h-2.047v-0.522h2.606v4.385h-0.559V249.382z" />
                        <path d="M363.299,250.792v-9.024h0.649v9.024H363.299z" />
                        <path d="M369.147,241.769h0.559l3.813,9.024h-0.686l-1.259-2.974h-4.308l-1.259,2.974h-0.699L369.147,241.769z M371.396,247.285    l-1.969-4.729l-2.008,4.729H371.396z" />
                    </g> */}
                    </svg>
                </div>
            </div>
        </WorksItem>
    )
}